import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable, zip } from 'rxjs';
import { Assignment, CustomerListObject, EmployeeListObject, ProjectListObject } from 'src/app/shared/models';
import {
  AssignmentsService,
  CustomersService,
  EmployeesService,
  FeedbackService,
  ProjectsService,
} from 'src/app/shared/services';

interface AssignmentListObject {
  assignment: Assignment;
  project: ProjectListObject;
  customer: CustomerListObject;
  employee: EmployeeListObject;
}

@Component({
  selector: 'app-inzet',
  templateUrl: './assignment-overview.component.html',
  styleUrls: ['./assignment-overview.component.scss'],
})
export class AssignmentOverviewComponent implements OnInit, AfterViewInit {
  constructor(
    private assignmentsService: AssignmentsService,
    public dialog: MatDialog,
    private feedback: FeedbackService,
    private projectsService: ProjectsService,
    private employeesService: EmployeesService,
    private customersService: CustomersService,
    private router: Router,
  ) {}
  displayedColumns: string[] = [
    'employee.lastName',
    'employee.firstName',
    'project.name',
    'customer.name',
    'assignment.startDate',
    'assignment.endDate',
    'edit',
  ];
  dataSource = new MatTableDataSource<AssignmentListObject>();

  assignments: AssignmentListObject[];

  error = false;
  filterControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  showStandardAssignments = false;
  showExpiredAssignments = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  static isExpiredAssignment(assignment: Assignment): boolean {
    const startDate = moment(assignment.startDate);
    const endDate = moment(assignment.endDate);
    const now = moment();
    return endDate.isValid() && !now.isBetween(startDate, endDate, 'days', '[]');
  }

  ngOnInit(): void {
    this.fetch();

    this.dataSource.filterPredicate = (item, str) => !!this._filterItem(item, str);

    this.dataSource.sortingDataAccessor = (item, property) => {
      return property.split('.').reduce((object, key) => (object ? object[key] : undefined), item);
    };

    this.triggerSort('employee.lastName');
  }

  private triggerSort(val: string) {
    this.sort.active = val;
    this.dataSource.sort = this.sort;
    const sortState: Sort = { active: val, direction: this.sort.direction === 'asc' ? 'desc' : 'asc' };
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  private _filter(value: string): string[] {
    const filterValue = value.trim().toLowerCase();
    const result = [];

    for (const item of this.dataSource.data) {
      const eligible = this._filterItem(item, filterValue);
      if (eligible) result.push(eligible);
    }

    return result;
  }

  private _filterItem(item: AssignmentListObject, filterValue: string) {
    if (item.employee && item.employee.name.toLowerCase().indexOf(filterValue) >= 0) {
      return item.employee.name;
    } else if (item.project.name.toLowerCase().indexOf(filterValue) >= 0) {
      return item.project.name;
    } else if (item.customer.name.toLowerCase().indexOf(filterValue) >= 0) {
      return item.customer.name;
    }
  }

  editAssignment(id: number): void {
    this.router.navigate(['/admin/inzet', id]);
  }

  addAssignment(): void {
    this.router.navigate(['admin', 'inzet']);
  }

  private fetch(): void {
    zip(
      this.assignmentsService.getOrFetchAll(),
      this.projectsService.getOrFetchAll(),
      this.customersService.getOrFetchAll(),
      this.employeesService.getOrFetchAll(),
    ).subscribe(([assignments, projects, customers, employees]) => {
      const projectById = Object.fromEntries(projects.map((p) => [p.id, p]));
      const customerById = Object.fromEntries(customers.map((c) => [c.id, c]));
      const employeeById = Object.fromEntries(employees.map((e) => [e.id, e]));

      this.assignments = assignments.map((a) => ({
        assignment: a,
        project: projectById[a.projectId],
        customer: customerById[a.project.customerId],
        employee: employeeById[a.employeeId],
      }));

      this.applyData();
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    const val = filterValue.trim().toLowerCase();
    this.dataSource.filter = val;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.filteredOptions = new Observable<string[]>((observer) => {
      observer.next(this._filter(val));
    });
  }

  triggerFilter(): void {
    this.applyData();
  }

  showsStandardAssignments(value: boolean): void {
    this.showStandardAssignments = value;
    this.triggerFilter();
  }

  showsExpiredAssignments(value: boolean): void {
    this.showExpiredAssignments = value;
    this.triggerFilter();
  }

  private applyData() {
    this.dataSource.data = this.assignments.filter((alo) => {
      if (!this.showStandardAssignments && alo.customer.isInternal()) {
        return false;
      }
      return this.showExpiredAssignments || !AssignmentOverviewComponent.isExpiredAssignment(alo.assignment);
    });
  }

  confirmDialog(row: Assignment): void {
    this.feedback.getDeleteConfirmation('Inzet op ' + row.project.name).subscribe((dialogResult) => {
      this.onDialogResult(row.id, dialogResult);
    });
  }

  private onDialogResult(id, dialogResult) {
    if (dialogResult) {
      this.assignmentsService.delete(id).subscribe(
        () => {
          this.feedback.openSuccessToast('De wijzigingen zijn opgeslagen');
        },
        (err) => {
          const item = this.dataSource.data.find((alo) => alo.assignment.id === id);
          if (item) item.assignment.deletable = false;
          this.feedback.openErrorToast(err);
        },
      );
    }
  }

  isDeletable(row: Assignment): boolean {
    return row.deletable;
  }
}
