import { Injectable } from '@angular/core';
import {
  Configuration,
  Employee as EmployeeDTO,
  EmployeeCreate,
  EmployeeListObject as EmployeeListObjectDTO,
  EmployeesService as Swagger,
  PasswordNew,
  PasswordReset,
  PasswordSet,
} from 'generated-sources';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Employee, EmployeeListObject } from 'src/app/shared/models';
import { HttpService } from './http';
import { ResourceService } from './resource.service';

@Injectable({ providedIn: 'root' })
export class EmployeesService extends ResourceService<
  Employee,
  EmployeeDTO,
  EmployeeListObject,
  EmployeeListObjectDTO
> {
  private readonly proxy: Swagger;

  constructor(private http: HttpService) {
    super(http);
    this.proxy = new Swagger(http.asClient, this.backend, new Configuration());
  }

  protected get name(): string {
    return 'employee';
  }

  create(employee: Employee): Observable<Employee> {
    const create: EmployeeCreate = this.modelToDto(employee);
    return this.proxy.createUsingPOST2(create).pipe(this.mapSingle);
  }

  get(id: number): Observable<Employee> {
    return this.proxy.getEmployeeUsingGET(id).pipe(this.mapSingle);
  }

  getAll(): Observable<Array<EmployeeListObject>> {
    return this.proxy.getAllUsingGET().pipe(this.mapList);
  }

  getDeleted(): Observable<Array<EmployeeListObject>> {
    return this.proxy.getDeletedUsingGET().pipe(this.mapList);
  }

  update(id: number, employee: Employee): Observable<Employee> {
    const dto = this.modelToDto(employee);
    return this.proxy.updateUsingPUT2(dto, id).pipe(this.mapSingle);
  }

  delete(id: number): Observable<unknown> {
    return this.proxy.deleteUsingDELETE2(id);
  }

  undelete(id: number): Observable<unknown> {
    return this.proxy.undeleteUsingPOST(id);
  }

  protected modelToDto(employee: Employee): EmployeeDTO {
    return {
      id: employee.id,
      firstName: employee.firstName,
      lastName: employee.lastName,
      nickName: employee.nickName,
      userName: employee.userName,
      email: employee.email,
      contractDate: moment(employee.contractDate).format(this.dateFormat),
      endDate: employee.endDate ? moment(employee.endDate).format(this.dateFormat) : undefined,
      contractHours: employee.contractHours,
      holidayHoursCurrentYear: employee.holidayHoursCurrentYear,
      holidayHoursPreviousYear: employee.holidayHoursPreviousYear,
      approvedHolidayHours: employee.approvedHolidayHours,
      submittedHolidayHours: employee.submittedHolidayHours,
      holidayYear: employee.holidayYear,
      roles: employee.roles,
    };
  }

  protected dtoToModel(a: EmployeeDTO): Employee {
    return new Employee(a);
  }

  protected listDtoToModel(dto: EmployeeListObjectDTO): EmployeeListObject {
    return new EmployeeListObject(dto);
  }

  getOrFetchAll(): Observable<EmployeeListObject[]> {
    return this.proxy.getAllUsingGET().pipe(this.mapList);
  }

  getLoggedInEmployee(): Promise<Employee> {
    return this.proxy.userIdUsingGET().pipe(this.mapSingle).toPromise();
  }

  resetPassword(address: string, token: string): Observable<unknown> {
    const dto: PasswordReset = {
      address: address,
      token: token,
    };
    return this.proxy.resetPasswordUsingPOST(dto);
  }

  setPassword(password: string, token: string): Observable<unknown> {
    const dto: PasswordSet = {
      password: password,
      token: token,
    };
    return this.proxy.setPasswordUsingPOST(dto);
  }

  setNewPassword(currentPassword: string, newPassword: string): Observable<unknown> {
    const dto: PasswordNew = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    return this.proxy.setNewPasswordUsingPOST(dto);
  }
}
