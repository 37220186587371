import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Model } from 'src/app/shared/models';

@Component({
  selector: 'app-ura-overview',
  templateUrl: './ura-overview.component.html',
  styleUrls: ['./ura-overview.component.scss'],
})
export class UraOverviewComponent<T extends Model> implements OnInit, AfterViewInit, OnDestroy {
  constructor(private router: Router) {
    this.navigationSubscription = this.router.events.subscribe((e: unknown) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.fetchData(); // zodat bij navigatie naar zelfde URL het component ververst wordt
      }
    });
  }
  @Input() entityType: string;
  @Input() displayedFields: string[];
  @Input() dataObservable: Observable<Array<T>>;
  @Input() showAddButton = true;
  @Input() useArchiving = false;
  @Output() add: EventEmitter<unknown> = new EventEmitter();
  @Output() edit: EventEmitter<unknown> = new EventEmitter();
  @Output() delete: EventEmitter<unknown> = new EventEmitter();
  @Output() restore: EventEmitter<unknown> = new EventEmitter();
  @Output() overview: EventEmitter<unknown> = new EventEmitter();

  dataSource = new MatTableDataSource<T>();
  error = false;
  subscription: Subscription;
  displayName: string;

  filterControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private navigationSubscription: Subscription;

  ngOnInit(): void {
    this.fetchData();

    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };
    this.triggerSort(this.displayedFields[0]);
    this.displayName = this.entityType.charAt(0).toUpperCase() + this.entityType.slice(1);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  applyFilter(filterValue: string): void {
    const val = filterValue.trim().toLowerCase();
    this.dataSource.filter = val;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.filteredOptions = new Observable<string[]>((observer) => {
      const next = this._filter(val);
      observer.next(next);
    });
  }

  triggerSort(val: string): void {
    this.sort.active = val;
    this.dataSource.sort = this.sort;
    const sortState: Sort = { active: val, direction: this.sort.direction === 'asc' ? 'desc' : 'asc' };
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  isDeletable(row: Model): boolean {
    return row.deletable;
  }

  isDateType(item: unknown): boolean {
    return this.hasIsValid(item) && item.isValid();
  }

  isBooleanType(item: unknown): boolean {
    return typeof item === 'boolean';
  }

  onAdd(): void {
    this.add.emit();
  }

  onEdit(row: Model): void {
    this.edit.emit(row);
  }

  onDelete(row: Model): void {
    this.delete.emit(row);
  }

  onRestore(row: Model): void {
    this.restore.emit(row);
  }

  onOverview(row: Model): void {
    this.overview.emit(row);
  }

  canEdit(): boolean {
    return this.hasObservers(this.edit);
  }

  canDelete(): boolean {
    return this.hasObservers(this.delete);
  }

  canRestore(): boolean {
    return this.hasObservers(this.restore);
  }

  private fetchData(): void {
    if (this.dataObservable) {
      this.subscription = this.dataObservable.subscribe(
        (response: T[]) => {
          this.dataSource.data = response;
        },
        () => {
          this.error = true;
        },
      );
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.trim().toLowerCase();
    const result = [];

    if (filterValue.length === 0) {
      return result;
    }

    for (const item of this.dataSource.data) {
      if (this.hasName(item)) {
        if (item.name.toLowerCase().indexOf(filterValue) >= 0) {
          result.push(item.name);
        }
      }
    }
    return result;
  }

  private hasObservers(emitter: EventEmitter<unknown>): boolean {
    return emitter.observers.length > 0;
  }

  private hasName(item: T): item is T & { name: string } {
    return 'name' in item && typeof item['name'] === 'string';
  }

  private hasIsValid(item: unknown): item is { isValid: () => boolean } {
    return item && typeof item === 'object' && 'isValid' in item && typeof item['isValid'] === 'function';
  }
}
