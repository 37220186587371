import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderModule } from './header/header.module';
import { SidenavModule } from './sidenav/sidenav.module';
import { LoginModule } from './login/login.module';
import { PopupComponent } from './popup/popup.component';

const modules = [HeaderModule, SidenavModule, LoginModule];

@NgModule({
  declarations: [PopupComponent],
  imports: [CommonModule, ...modules],
  exports: [...modules, PopupComponent],
})
export class CoreModule {}
