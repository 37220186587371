import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

class SplitHours {
  constructor(
    public hourPart: number,
    public minutePart: number,
  ) {}
}

export interface HoursInputValueType {
  parts: { controls: { hourPart: { value: number }; minutePart: { value: number } } };
}

@Component({
  selector: 'app-hours-input',
  templateUrl: './hours-input.component.html',
  styleUrls: ['./hours-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: HoursInputComponent }],
})
export class HoursInputComponent implements MatFormFieldControl<SplitHours>, OnDestroy, ControlValueAccessor {
  static nextId = 0;

  public parts: UntypedFormGroup;
  private inputPattern = '^[0-9]{0,2}$';
  public stateChanges = new Subject<void>();
  public focused = false;
  private _onChange: (_: unknown) => void;
  private _onTouched: (_: unknown) => void;

  @Input()
  get value(): SplitHours | null {
    const thisEffort = this.parts.value;
    if (
      (thisEffort.hourPart < 24 && thisEffort.minutePart < 59) ||
      (thisEffort.hourPart === 24 && thisEffort.minutePart === 0)
    ) {
      return new SplitHours(thisEffort.hourPart, thisEffort.minutePart);
    }
    return null;
  }
  set value(effort: SplitHours | null) {
    effort = effort || new SplitHours(0, 0);
    this.parts.setValue({ hourPart: effort.hourPart, minutePart: effort.minutePart });
    this.stateChanges.next();
  }

  constructor(
    private fb: UntypedFormBuilder,
    @Optional() public parentFormField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    this.parts = this.fb.group({
      hourPart: [{ validators: [Validators.pattern(this.inputPattern)], updateOn: 'change' }],
      minutePart: [{ validators: [Validators.pattern(this.inputPattern)], updateOn: 'change' }],
    });

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty(): boolean {
    const n = this.parts.value;
    return n.hourPart === null && n.minutePart === null;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.parts.disable();
    } else {
      this.parts.enable();
    }
    this.stateChanges.next();
  }
  private _disabled = false;

  errorState = false;

  controlType = 'app-hours-input';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @HostBinding() id = `hours-input-${HoursInputComponent.nextId++}`;

  @HostBinding('class.floating')
  // Not used anymore
  get shouldLabelFloat(): boolean {
    return false;
  }

  @HostBinding('class.hide')
  get shouldSpanHide(): boolean {
    return !(this.parts.value.hourPart != 0 || this.parts.value.minutePart > 0);
  }

  @HostBinding('class.disabled')
  get shouldSpanDisable(): boolean {
    return this.disabled;
  }

  @HostListener('click', ['$event'])
  onContainerClick(event: MouseEvent): void {
    // focus on first input box
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elRef.nativeElement;
    controlElement.setAttribute('aria-describedby', [this.userAriaDescribedBy, ...ids].join(' '));
  }

  writeValue(value: number): void {
    if (value) {
      const hourPart = Math.trunc(value);
      const minutePart = Math.trunc(Math.abs(value - hourPart) * 60);
      this.parts.setValue({ hourPart, minutePart });
      if (hourPart < 0) {
        // negative efforts can only be modified through the month overview component, this component can only view.
        this.disabled = true;
      }
    } else {
      this.parts.setValue({ hourPart: null, minutePart: null });
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouched = fn;
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
}
