import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupStateService {
  private displayPopupSubject = new BehaviorSubject<boolean>(false);
  displayPopup$ = this.displayPopupSubject.asObservable();

  setDisplayPopup(value: boolean): void {
    this.displayPopupSubject.next(value);
  }
}
