<div id="news-dialog">
  <h2 translate>news-popup.title</h2>

  <div mat-dialog-content class="dialog-content" *ngIf="newsItems && newsItems.length > 0">
    <div *ngFor="let newsItem of newsItems" class="news-item">
      <a href="{{ newsItem.webUrl }}" target="_blank" (click)="onLinkClick(newsItem.id)"> {{newsItem.title}} </a>
      <span> {{ newsItem.publishedOnDate | date }} </span>
      <mat-icon class="icon">open_in_new</mat-icon>
    </div>
  </div>

  <div mat-dialog-content class="dialog-content" *ngIf="!newsItems || newsItems.length === 0">
    <p translate>news-popup.empty</p>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" translate>
      <mat-icon>close</mat-icon>
      Annuleren
    </button>
  </div>
</div>
