import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GeneratedPasswordDialogComponent } from 'src/app/shared/components/dialogs/generated-password-dialog/generated-password-dialog.component';
import { UserRole } from 'src/app/shared/enums/UserRole';
import { UraValidators } from 'src/app/shared/helper/ura-validators';
import { Employee } from 'src/app/shared/models';
import { EmployeesService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-employee-add',
  templateUrl: './employee-add.component.html',
  styleUrls: ['../../general.component.scss', './employee-add.component.scss'],
})
export class EmployeeAddComponent implements AfterViewInit, OnInit {
  // ViewChild for autofocus, as of 3-3-2020 the autofocus attribute in html is still broken and stops working after cancelling the form once
  // and then reentering te form, in order to keep it working this has been implemented
  // See: https://stackoverflow.com/questions/56394068/autofocus-not-working-after-remove-and-adding-again-to-the-dom
  @ViewChild('userName', { static: true }) focusElement: ElementRef;
  contentForm: UntypedFormGroup;

  username_pattern = /^[a-zA-Z][\w-]{4,29}$/;
  email_pattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$';
  hours_pattern = '^[1-9][0-9]?$';
  holiday_pattern = '^-?\\d{0,3}(\\.\\d{1,2})?$';

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private feedback: FeedbackService,
    public employeesService: EmployeesService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.contentForm = this.formBuilder.group({
      userName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.username_pattern),
          Validators.minLength(5),
          Validators.maxLength(30),
        ]),
      ],
      nickName: ['', Validators.compose([Validators.required, Validators.maxLength(40)])],
      firstName: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      lastName: ['', Validators.compose([Validators.required, Validators.maxLength(40)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.email_pattern)])],
      contractDate: ['', [Validators.required]],
      contractHours: [
        '',
        Validators.compose([Validators.required, Validators.max(40), Validators.pattern(this.hours_pattern)]),
      ],
      holidayHoursPreviousYear: [
        '',
        Validators.compose([Validators.required, Validators.pattern(this.holiday_pattern)]),
      ],
      holidayHoursCurrentYear: [
        '',
        Validators.compose([Validators.required, Validators.pattern(this.holiday_pattern)]),
      ],
      approvedHolidayHours: ['', Validators.compose([Validators.required, Validators.pattern(this.holiday_pattern)])],
      roles: this.formBuilder.group(
        {
          administrator: [false],
          accounting: [false],
          contentManager: [false],
          employee: [true],
        },
        { validator: UraValidators.rolesRequired },
      ),
    });
  }

  submit(form: NgForm): void {
    const employee = this.getEmployee(form);

    this.employeesService.create(employee).subscribe(
      (response) => {
        this.feedback.openSuccessToast('De nieuwe medewerker is toegevoegd');

        this.dialog.open(GeneratedPasswordDialogComponent, {
          data: response,
          width: '550px',
          disableClose: true,
        });
        this.router.navigate(['admin', 'medewerkers']);
      },
      (err) => {
        this.feedback.openErrorToast(err);
      },
    );
  }

  cancel(): void {
    this.feedback.openNeutralToast();
    this.router.navigate(['admin', 'medewerkers']);
  }

  ngAfterViewInit(): void {
    this.focusElement.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  acceptNumericOnly(event: InputEvent): void {
    if (!/^[0-9,]*$/.test(event.data || '')) {
      event.preventDefault();
    }
  }

  private getEmployee(form: NgForm): Employee {
    const employee = new Employee(form.value);
    employee.roles = this.getUserRoles(form.value.roles);
    return employee;
  }

  private getUserRoles(dtoRoles: Array<{ key: string; value: boolean }>): Array<string> {
    const roles: string[] = [];
    if (dtoRoles['administrator']) {
      roles.push(UserRole.ADMINISTRATOR);
    }
    if (dtoRoles['accounting']) {
      roles.push(UserRole.ACCOUNTING);
    }
    if (dtoRoles['contentManager']) {
      roles.push(UserRole.CONTENT_MANAGER);
    }
    if (dtoRoles['employee']) {
      roles.push(UserRole.EMPLOYEE);
    }
    return roles;
  }
}
