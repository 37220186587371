import { Injectable } from '@angular/core';

import { Configuration, Token, TokenService as Swagger } from 'generated-sources';
import { HttpService } from './http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private readonly proxy: Swagger;

  protected backend = environment.servicesUrl;

  constructor(private http: HttpService) {
    this.proxy = new Swagger(http.asClient, this.backend, new Configuration());
  }

  getToken(username: string, password: string): Observable<Token> {
    this.proxy.defaultHeaders = new HttpHeaders({
      Authorization: 'Basic ' + btoa(username + ':' + password),
      Origin: '*',
    });

    return this.proxy.token();
  }
}
