/**
 * XLence Urenregistatie API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DailyEffort } from './dailyEffort';
import { Project } from './project';

export interface Assignment { 
    id?: number;
    startDate: string;
    endDate?: string;
    hoursPerWeek: number;
    project: Project;
    employeeId: number;
    dailyEfforts?: Array<DailyEffort>;
    deletable?: boolean;
}