<h2 translate>Profiel</h2>

<div class="header-wrapper">
  <mat-accordion *ngIf="employee" multi>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title translate> Persoonlijke gegevens </mat-panel-title>
        <mat-panel-description>
          <mat-icon color="primary">account_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>{{ 'Roepnaam' | translate }}</mat-label>
        <input
          matInput
          maxlength="50"
          [(ngModel)]="employee.nickName"
          name="nickname"
          [readonly]="!editMode"
          #focus
          (keyup)="keyup($event)"
          (keyup.enter)="dirty && submit()"
          trim="blur"
        />
        <mat-error translate>Roepnaam mag maximaal 50 tekens bevatten</mat-error>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>{{ 'Voornaam' | translate }}</mat-label>
        <input
          matInput
          maxlength="50"
          [(ngModel)]="employee.firstName"
          name="firstName"
          [readonly]="!editMode"
          (keyup)="keyup($event)"
          (keyup.enter)="dirty && submit()"
          trim="blur"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Achternaam' | translate }}</mat-label>
        <input
          matInput
          maxlength="50"
          [(ngModel)]="employee.lastName"
          name="lastName"
          [readonly]="!editMode"
          (keyup)="keyup($event)"
          (keyup.enter)="dirty && submit()"
        />
      </mat-form-field>
      <br />
      <mat-form-field class="formFieldWidth29 mat-mdc-form-field">
        <mat-label>{{ 'E-mailadres' | translate }}</mat-label>
        <input
          matInput
          type="email"
          [(ngModel)]="employee.email"
          name="email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          [readonly]="!editMode"
          [ngClass]="{ blocked: editMode }"
          (keyup)="keyup($event)"
          (keyup.enter)="dirty && submit()"
          trim="blur"
        />
      </mat-form-field>
      <div class="btn_edit" *ngIf="isAdministrator">
        <button *ngIf="editMode" mat-raised-button (click)="cancel()">
          <mat-icon>close</mat-icon>
          {{ 'Annuleren' | translate }}
        </button>
        &nbsp;
        <button
          mat-raised-button
          color="primary"
          [ngClass]="{ edit: editMode }"
          *ngIf="!editMode"
          (click)="toggleEdit()"
        >
          <mat-icon>edit</mat-icon>
          {{ 'Bewerken' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="submit()" *ngIf="editMode" [disabled]="!dirty">
          <mat-icon>done</mat-icon>
          {{ 'Opslaan' | translate }}
        </button>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel id="profile-info">
      <mat-expansion-panel-header>
        <mat-panel-title translate> Profielgegevens </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>{{ 'Gebruikersnaam' | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="employee.userName"
          name="username"
          readonly
          [ngClass]="{ blocked: editMode }"
          trim="blur"
        />
      </mat-form-field>
      <div>
        <button mat-stroked-button color="primary" (click)="changePassword()">
          {{ 'Wachtwoord wijzigen' | translate }}
        </button>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel id="profile-contract">
      <mat-expansion-panel-header>
        <mat-panel-title> Contractgegevens </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field>
        <mat-label>{{ 'Ingangsdatum contract' | translate }}</mat-label>
        <input
          matInput
          type="date"
          [(ngModel)]="employeeContractDate"
          name="contractDate"
          readonly
          [ngClass]="{ blocked: editMode }"
        />
      </mat-form-field>
      <mat-form-field *ngIf="!!employee.endDate">
        <mat-label>{{ 'Beëindigingsdatum contract' | translate }}</mat-label>
        <input
          matInput
          type="date"
          [(ngModel)]="employeeEndDate"
          name="endDate"
          readonly
          [ngClass]="{ blocked: editMode }"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'Contract-uren' | translate }}</mat-label>
        <input
          matInput
          type="string"
          name="contractHours"
          [(ngModel)]="employee.contractHours"
          readonly
          [ngClass]="{ blocked: editMode }"
        />
        <mat-error>Verplicht Veld</mat-error>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel id="profile-leave">
      <mat-expansion-panel-header>
        <mat-panel-title>Verlof</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let field of holidayBalances">
        <mat-form-field appearance="fill">
          <mat-label>{{ field.description | translate }}</mat-label>
          <input
            type="string"
            matInput
            [name]="field.name"
            [ngModel]="field.value"
            placeholder="{{ field.description | translate }}"
            readonly
            [ngClass]="{ blocked: editMode }"
          />
        </mat-form-field>
        <br />
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>
