export * from './assignments.service';
import { AssignmentsService } from './assignments.service';
export * from './customers.service';
import { CustomersService } from './customers.service';
export * from './dailyefforts.service';
import { DailyeffortsService } from './dailyefforts.service';
export * from './employees.service';
import { EmployeesService } from './employees.service';
export * from './menu.service';
import { MenuService } from './menu.service';
export * from './projects.service';
import { ProjectsService } from './projects.service';
export * from './sharepoint.service';
import { SharepointService } from './sharepoint.service';
export * from './token.service';
import { TokenService } from './token.service';
export const APIS = [AssignmentsService, CustomersService, DailyeffortsService, EmployeesService, MenuService, ProjectsService, SharepointService, TokenService];
