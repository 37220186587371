import { Component, OnInit, OnDestroy } from '@angular/core';
import { News } from 'generated-sources';
import { PopupStateService, SharepointService } from 'src/app/shared/services';
import { MatDialog } from '@angular/material/dialog';
import { NewsDialogComponent } from 'src/app/shared/components/dialogs/news-dialog/news-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup',
  template: '',
})
export class PopupComponent implements OnInit, OnDestroy {
  displayPopup = false;
  employeeId: number;
  popupSubscription: Subscription;

  constructor(
    private popupStateService: PopupStateService,
    public dialog: MatDialog,
    private sharepointService: SharepointService,
  ) {}

  ngOnInit(): void {
    this.popupSubscription = this.popupStateService.displayPopup$.subscribe((displayPopup) => {
      this.displayPopup = displayPopup.valueOf();
      if (this.displayPopup) {
        this.fetchLatestNewsAndOpenDialog();
      }
    });
  }

  private fetchLatestNewsAndOpenDialog(): void {
    this.sharepointService.getLatestNewsForEmployee().subscribe((newsItems) => {
      if (newsItems.length > 0) {
        this.openDialog(newsItems);
      }
    });
  }

  private openDialog(newsItems: News[]): void {
    this.dialog.open(NewsDialogComponent, { data: newsItems });
  }

  ngOnDestroy(): void {
    if (this.popupSubscription) {
      this.popupSubscription.unsubscribe();
    }
  }
}
