import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';

import { DialogsModule } from './dialogs/dialogs.module';
import { WeekHoursModule } from './week-hours/week-hours.module';
import { PipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { AdminModule } from 'src/app/admin/admin.module';
import { UraOverviewModule } from 'src/app/shared/components/ura-overview/ura-overview.module';

const modules = [AdminModule, DialogsModule, WeekHoursModule, PipesModule, UraOverviewModule];

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, TranslateModule, ...modules],
  exports: [LoaderComponent],
})
export class ComponentsModule {}
