import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import { Employee } from 'src/app/shared/models';
import { EmployeesService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-archive',
  templateUrl: './archive-overview.component.html',
})
export class ArchiveOverviewComponent {
  displayedColumns: string[] = ['lastName', 'firstName', 'userName', 'edit', 'hoursOverview'];

  constructor(
    public employeeService: EmployeesService,
    public dialog: MatDialog,
    public feedback: FeedbackService,
    private router: Router,
  ) {}

  confirmDialog(row: Employee): void {
    this.feedback.getRestoreConfirmation('medewerker ' + row.firstName).subscribe((dialogResult) => {
      this.onDialogResult(row.id, dialogResult);
    });
  }

  linkHourOverview(row: Employee): void {
    const now = moment().subtract(1, 'months');
    this.router.navigate(['admin', 'urenoverzicht', row.id, now.year(), now.month() + 1]);
  }

  private onDialogResult(id, dialogResult) {
    if (dialogResult) {
      // this.employeeService.
      this.employeeService.undelete(id).subscribe(
        () => {
          this.feedback.openSuccessToast('Medewerker is hersteld');
          this.router.navigate(['admin', 'medewerkers']);
        },
        (err) => {
          this.feedback.openErrorToast(err);
        },
      );
    }
  }
}
