export class KeyUp {
  private static BACKSPACE = 8;
  private static ZERO = 48;
  private static Z = 122;

  public static charactersAndBackspace(e: KeyboardEvent): boolean {
    const code = e.key && e.key.charCodeAt(0);
    return (code <= KeyUp.Z && code >= KeyUp.ZERO) || code === KeyUp.BACKSPACE;
  }
}
