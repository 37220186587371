import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Customer } from 'src/app/shared/models';
import { CustomersService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-customers',
  templateUrl: './customers-overview.component.html',
})
export class CustomersOverviewComponent {
  displayedColumns: string[] = ['name', 'edit'];

  constructor(
    public customersService: CustomersService,
    public dialog: MatDialog,
    private feedback: FeedbackService,
    private router: Router,
  ) {}

  addCustomer(): void {
    this.router.navigate(['admin', 'klant']);
  }

  editCustomer(row: Customer): void {
    this.router.navigate(['/admin/klant', row.id]);
  }

  // TODO fix delete
  confirmDialog(row: Customer): void {
    this.feedback.getDeleteConfirmation('Klant ' + row.name).subscribe((dialogResult) => {
      this.onDialogResult(row.id, dialogResult);
    });
  }

  private onDialogResult(id, dialogResult) {
    if (dialogResult) {
      this.customersService.delete(id).subscribe(
        () => {
          this.feedback.openSuccessToast('Klant is verwijderd');
          this.router.navigate(['admin', 'klanten']);
        },
        (err) => {
          this.feedback.openErrorToast(err);
        },
      );
    }
  }
}
