import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmployeesService } from 'src/app/shared/services';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  editable = true;
  isLinear = true;
  secondFormGroup: UntypedFormGroup;
  address = '';

  @ViewChild(MatStepper, { static: true }) private stepper: MatStepper;

  constructor(
    private employeesService: EmployeesService,
    private _formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
  }

  resetPassword(address: string): void {
    this.editable = false;
    this.stepper.next();

    this.employeesService.resetPassword(address, '').subscribe(
      () => {
        // success
      },
      () => {
        // snackBar
      },
    );
  }
}
