<div id="header">
  <mat-toolbar color="primary">
    <div>
      <button *ngIf="loggedIn()" mat-icon-button (click)="onToggleSidenav()"><mat-icon>menu</mat-icon></button>
    </div>
    <div>
      <img class="logo" alt="Logo Ura" src="../../../assets/logo-icon.png" />
    </div>
    <div class="branding"><p>URA</p></div>

    <div id="userbox">
      <ng-container *ngIf="loggedIn()">
        <ul class="navigation">
          <li>
            <div class="hide">
              <div class="circle hide-item" style="line-height: 200%">{{ nickName.charAt(0) | uppercase }}</div>
              <img class="avatar" alt="Unicorn avatar" src="../../../assets/unicorn.svg" />
            </div>
          </li>
          <li>
            <h1 class="navigation-item" translate [translateParams]="{name: nickName}">header.hello</h1>
          </li>
          <li class="navigation-item"><a (click)="logout()" translate>header.logout</a></li>
        </ul>
      </ng-container>
    </div>
  </mat-toolbar>
</div>
