import { registerLocaleData } from '@angular/common';
import localeDutch from '@angular/common/locales/nl';
import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import 'moment/locale/nl';
import { LocaleService } from 'src/app/shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'xlence-uren';

  constructor(
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Moment>,
    private localeService: LocaleService,
  ) {
    translate.use(localeService.GetLocale());

    moment.locale(localeService.GetLocale());
    dateAdapter.setLocale(localeService.GetLocale());
    registerLocaleData(localeDutch);
  }
}
