<section id="hours-overview-detail">
  <div class="header-wrapper">
    <div class="mat-elevation-z8 mat-mdc-table">
      <img id="xlence-logo" class="printonly" alt="XLence Companies" src="../../../assets/XLENCE_companies_logo.png" />
      <h1 class="noprint">{{ employee?.firstName }} {{ employee?.lastName }}</h1>
      <mat-form-field class="printonly employee-label mat-mdc-form-field">
        <mat-label>Medewerker</mat-label>
        <input matInput type="input" [value]="employee?.firstName + ' ' + employee?.lastName " />
      </mat-form-field>

      <button mat-icon-button (click)="previousMonth()"><mat-icon>chevron_left</mat-icon></button>
      <mat-form-field id="monthpicker" style="width: 200px">
        <input
          matInput
          [matDatepicker]="dp"
          (click)="dp.open()"
          placeholder="Maand en jaar"
          [formControl]="date"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker
          #dp
          startView="year"
          (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event); dp.close()"
        >
        </mat-datepicker>
      </mat-form-field>
      <button mat-icon-button (click)="nextMonth()"><mat-icon>chevron_right</mat-icon></button>
      <button mat-icon-button color="primary" (click)="thisMonth()" [disabled]="!this.date || isSame()">
        <mat-icon>home</mat-icon>
      </button>
      <div class="icon-buttons">
        <button
          *ngIf="isAdministrator || isAccounting"
          mat-mini-fab
          class="icon-button"
          matTooltipClass="tooltip-blue"
          matTooltip="Goedkeuren"
          [disabled]="editMode"
          (click)="approveAll()"
        >
          <mat-icon matSuffix>check</mat-icon>
        </button>
        <button
          *ngIf="isAdministrator || isAccounting"
          mat-mini-fab
          class="icon-button"
          matTooltipClass="tooltip-blue"
          matTooltip="Reset"
          (click)="reset()"
        >
          <mat-icon matSuffix>redo</mat-icon>
        </button>
        <button
          *ngIf="isAdministrator || isAccounting"
          mat-mini-fab
          class="icon-button"
          [ngClass]="{ 'edit-active': editMode }"
          matTooltipClass="tooltip-blue"
          (click)="toggleEditMode()"
          matTooltip="Aanpassen"
        >
          <mat-icon matSuffix>edit</mat-icon>
        </button>
        <button
          mat-mini-fab
          class="icon-button"
          [disabled]="editMode"
          matTooltipClass="tooltip-blue"
          matTooltip="Printen"
          (click)="print()"
        >
          <mat-icon matSuffix>print</mat-icon>
        </button>
        <button
          *ngIf="isAdministrator || isAccounting"
          mat-mini-fab
          class="icon-button"
          matTooltipClass="tooltip-blue"
          matTooltip="Export"
          (click)="showExportDialog()"
        >
          <mat-icon matSuffix>get_app</mat-icon>
        </button>
      </div>
      <table id="urenoverzicht-table" class="mat-mdc-table">
        <!-- Overzicht uren per maand -->
        <thead class="mat-elevation">
          <tr>
            <th scope="col" class="head-table" colspan="2"></th>
            <th
              *ngIf="countDays[0].daysInMonths.isoWeekday() !== 1"
              [attr.colspan]="8 - countDays[0].daysInMonths.isoWeekday()"
            ></th>
            <ng-template ngFor let-day [ngForOf]="countDays">
              <th class="weeknumber" *ngIf="day.daysInMonths.isoWeekday() === 1" colspan="7">
                Week {{ day.daysInMonths.isoWeek() }}
              </th>
            </ng-template>
          </tr>
          <tr>
            <th scope="col" class="head-table">Project</th>
            <th scope="col" class="head-table"></th>
            <th
              scope="col"
              class="approve-column-header"
              *ngFor="let day of countDays"
              [ngClass]="{ weekend: day.isWeekend }"
            >
              <div>{{ day.daysInMonths | amDateFormat: 'dd' }}</div>
              <div>{{ day.daysInMonths | amDateFormat: 'DD' }}</div>
            </th>
            <th scope="col" class="head-table">Totaal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Thuis gewerkt</td>
            <td class="noprint"></td>
            <ng-template ngFor let-day [ngForOf]="countDays">
              <td>
                <!--{{day.date}} -->
                <div [hidden]="!showWorkedAtLocationCheckbox(day)">
                  <mat-checkbox
                    class="noprint"
                    [checked]="workedAtHome(day)"
                    [disabled]="!editMode"
                    (change)="changeWorkAtHome(day, $event)"
                  >
                  </mat-checkbox>
                  <div [hidden]="!hasSubmittedHours(day)">
                    <input
                      [ngClass]="{ 'printonly': hasSubmittedHours(day) }"
                      type="checkbox"
                      [checked]="workedAtHome(day)"
                    />
                  </div>
                </div>
                <div [hidden]="showWorkedAtLocationCheckbox(day)">&nbsp;</div>
              </td>
            </ng-template>
            <td></td>
            <td></td>
            <td>{{ countWorkingAtHome() }}</td>
          </tr>
          <ng-container *ngFor="let row of rows">
            <tr data-test-id="hourstable" [ngClass]="{ 'empty-row': total(row) < 1, 'noprint': !row.print }">
              <td *ngIf="row.assignment">{{ row.assignment.project.name }}</td>
              <td *ngIf="!row.assignment">
                <mat-form-field class="mat-mdc-form-field">
                  <mat-select
                    [placeholder]="row && row.assignment ? row.assignment.project.name : 'Project'"
                    [(ngModel)]="row.assignment"
                    (selectionChange)="commitEfforts(row, row.assignment)"
                    required
                  >
                    <mat-option
                      *ngFor="let assignment of assignments"
                      [value]="assignment"
                      [innerText]="assignment.project.name"
                    >
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td class="noprint"><mat-checkbox [(ngModel)]="row.print"></mat-checkbox></td>
              <td
                class="day"
                *ngFor="let day of row.days; let i = index"
                (click)="editMode ? editEffort(day, row.assignment) : undefined"
                [ngClass]="{
              edit: day.editing,
              editable: editMode,
              ingevoerd: !day.data.submitted,
              weekend: day.weekend,
              modified: day.modified
            }"
              >
                <mat-icon class="goedgekeurd-icon" *ngIf="day.data.approved" label="Goedgekeurd"
                  >check_circle_outline</mat-icon
                >
                <mat-icon class="ingediend-icon" *ngIf="day.data.submitted && !day.data.approved" label="Ingediend"
                  >add_circle_outline</mat-icon
                >

                <span *ngIf="day.data.hours < 0 || day.data.hours > 0">{{ day.data.hours | DecimalToHours:true }}</span>
              </td>
              <td>{{ row.total | DecimalToHours:true }}</td>
            </tr>
          </ng-container>
        </tbody>
        <tfoot>
          <tr>
            <td [attr.colspan]="countDays.length + 2" class="centerButton">
              <button *ngIf="editMode" mat-flat-button class="button-regel" (click)="addEmptyRow()">
                <mat-icon>add_circle_outline</mat-icon>
                Voeg regel toe
              </button>
            </td>
            <td *ngIf="rows.length > 0" id="monthlyTotal" class="noprint">{{ monthlyTotal | DecimalToHours:true }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <table class="signature">
      <tr>
        <th>Datum:</th>
        <td>{{ today | date }}</td>
      </tr>
      <tr>
        <th>Naam:</th>
        <td></td>
      </tr>
      <tr>
        <th>Handtekening:</th>
        <td></td>
      </tr>
    </table>
  </div>
</section>
