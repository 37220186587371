import { Injectable } from '@angular/core';
import { Configuration, SharepointService as Swagger, News } from 'generated-sources';
import moment from 'moment';
import { Observable } from 'rxjs';
import { HttpService } from './http';
import { environment } from 'src/environments/environment';
import { Moment } from 'moment';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SharepointService {
  private readonly proxy: Swagger;
  protected backend = environment.servicesUrl;
  protected lastFetch: Moment;

  constructor(private http: HttpService) {
    this.proxy = new Swagger(http.asClient, this.backend, new Configuration());
  }

  getLatestNewsForEmployee(): Observable<Array<News>> {
    return this.proxy.getLatestNewsForEmployee().pipe(
      tap(() => {
        this.lastFetch = moment();
      }),
    );
  }

  markAsSeen(newsItemId: number): Observable<unknown> {
    return this.proxy.markAsSeen(newsItemId).pipe(
      tap(() => {
        this.lastFetch = moment();
      }),
    );
  }
}
