import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyUp } from 'src/app/shared/helper/keyup';
import { Customer } from 'src/app/shared/models';
import { CustomersService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['../../general.component.scss'],
})
export class CustomerEditComponent implements OnInit, AfterViewInit {
  dirty = false;

  id: number;
  customer: Customer = <Customer>{};
  contentForm: UntypedFormGroup;

  // ViewChild for autofocus, as of 3-3-2020 the autofocus attribute in html is still broken and stops working after cancelling the form once
  // and then reentering te form, in order to keep it working this has been implemented
  // See: https://stackoverflow.com/questions/56394068/autofocus-not-working-after-remove-and-adding-again-to-the-dom
  @ViewChild('name', { static: true }) focusElement: ElementRef;

  constructor(
    private router: Router,
    private feedback: FeedbackService,
    private route: ActivatedRoute,
    public customersService: CustomersService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.route.params.subscribe((params) => {
      this.getCustomer(params.id);
    });
  }

  initForm(): void {
    this.contentForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  private getCustomer(id: number) {
    this.customersService.fetch(id).subscribe((response) => {
      this.customer = response;
      this.initFormValues();
    });
  }

  keyup(e: KeyboardEvent): void {
    if (KeyUp.charactersAndBackspace(e)) this.dirty = true;
  }

  private initFormValues() {
    this.contentForm.controls['name'].setValue(this.customer.name);
  }

  submit(): void {
    this.updateCustomer();
    this.customersService.createOrUpdate(this.customer).subscribe(
      () => {
        this.feedback.openSuccessToast('De wijzigingen zijn opgeslagen');
        this.router.navigate(['admin', 'klanten']);
      },
      (err) => {
        this.feedback.openErrorToast(err);
      },
    );
  }

  private updateCustomer(): void {
    this.customer.name = this.contentForm.value['name'];
  }

  cancel(): void {
    this.feedback.openNeutralToast();
    this.router.navigate(['admin', 'klanten']);
  }

  ngAfterViewInit(): void {
    this.focusElement.nativeElement.focus();
    this.changeDetector.detectChanges();
  }
}
