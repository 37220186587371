/**
 * XLence Urenregistatie API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DailyEffort { 
    id?: number;
    assignmentId: number;
    day: string;
    hours: number;
    submittedByDate?: string;
    approvedByDate?: string;
    workedFromHome: boolean;
    deletable?: boolean;
    updatedBy?: string;
    minutes?: number;
    hasBeenSubmitted?: boolean;
}