import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../shared/material.module';
import { HoursOverviewComponent } from './hours-overview/hours-overview.component';
import { MomentModule } from 'ngx-moment';
import { HoursOverviewDetailComponent } from './hours-overview-detail/hours-overview-detail.component';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HoursOverviewComponent, HoursOverviewDetailComponent],
  exports: [HoursOverviewComponent],
  imports: [CommonModule, MaterialModule, MomentModule, PipesModule, TranslateModule],
})
export class HoursOverviewModule {}
