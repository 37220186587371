import { Component, OnInit } from '@angular/core';
import { CustomMenuItemsService, FeedbackService } from 'src/app/shared/services';
import { MenuItem } from 'src/app/shared/models';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-menu-item-list',
  templateUrl: './custom-menu-item-list.component.html',
  styleUrls: ['./custom-menu-item-list.component.scss'],
})
export class CustomMenuItemListComponent implements OnInit {
  menuItemArray: MenuItem[] = [];
  displayedColumns: string[] = ['name', 'url', 'edit'];

  constructor(
    public customMenuItemsService: CustomMenuItemsService,
    public dialog: MatDialog,
    public feedback: FeedbackService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.customMenuItemsService.getMenuItemsList().subscribe((items) => {
      this.menuItemArray = items;
    });
  }

  getCustomMenuItemList(): MenuItem[] {
    return this.menuItemArray;
  }

  editMenuItem(row: MenuItem): void {
    this.router.navigate(['/content/custom-menu-item-detail', row.id]);
  }

  addMenuItem(): void {
    this.router.navigate(['content', 'custom-menu-item-detail']);
  }

  confirmDialog(row: MenuItem): void {
    this.feedback.getDeleteConfirmation('customMenuItem ' + row.name).subscribe((dialogResult) => {
      this.onDialogResult(row.id, dialogResult);
    });
  }

  private onDialogResult(id, dialogResult) {
    if (dialogResult) {
      this.customMenuItemsService.delete(id).subscribe(
        () => {
          this.feedback.openSuccessToast('Custom Menu Item is verwijderd.');
          this.router.navigate(['content', 'custom-menu-item-list']);
        },
        (err) => {
          this.feedback.openErrorToast(err);
        },
      );
    }
  }
}
