import { Injectable } from '@angular/core';
import { Token } from 'generated-sources';
import { TokenService } from './token.service';
import moment, { now } from 'moment';
import { Navigation, NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly tokenKey = 'token';
  private readonly expirationKey = 'expires_at';
  private currentUrl: Navigation;

  constructor(
    private tokenService: TokenService,
    private router: Router,
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = router.getCurrentNavigation();
      }
    });
  }

  logIn(username: string, password: string): Promise<unknown> {
    return this.tokenService
      .getToken(username, password)
      .toPromise()
      .then(
        (token) => this.setSession(token),
        (err) => {
          sessionStorage.clear();
          throw err;
        },
      );
  }

  logOut(): void {
    sessionStorage.removeItem(this.tokenKey);
    sessionStorage.removeItem(this.expirationKey);
  }

  private setSession(token: Token) {
    sessionStorage.setItem(this.tokenKey, token.token);
    const expiresAt = moment(token.expiresAt);
    const tokenTimeout = new Date(token.expiresAt).getTime() - new Date().getTime();
    sessionStorage.setItem(this.expirationKey, expiresAt.toISOString());
    setTimeout(() => {
      this.logOut();
      this.router.navigate(['login'], {
        queryParams: {
          redirect: this.currentUrl.extractedUrl.toString(),
        },
      });
    }, tokenTimeout);
  }

  getAccessToken(): string {
    return sessionStorage.getItem(this.tokenKey);
  }

  hasValidToken(): boolean {
    return moment(sessionStorage.getItem(this.expirationKey)).isAfter(now());
  }
}
