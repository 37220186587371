/**
 * XLence Urenregistatie API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Employee { 
    id?: number;
    lastName: string;
    firstName: string;
    nickName: string;
    email: string;
    userName: string;
    roles: Array<string>;
    generated?: string;
    contractDate: string;
    endDate?: string;
    contractHours: number;
    holidayHoursPreviousYear: number;
    holidayHoursCurrentYear: number;
    approvedHolidayHours: number;
    submittedHolidayHours?: number;
    holidayYear: number;
    deletable?: boolean;
}