import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { KeyUp } from 'src/app/shared/helper/keyup';
import { Customer } from 'src/app/shared/models';
import { CustomersService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['../../general.component.scss'],
})
export class CustomerAddComponent implements OnInit, AfterViewInit {
  dirty = false;
  // ViewChild for autofocus, as of 3-3-2020 the autofocus attribute in html is still broken and stops working after cancelling the form once
  // and then reentering te form, in order to keep it working this has been implemented
  // See: https://stackoverflow.com/questions/56394068/autofocus-not-working-after-remove-and-adding-again-to-the-dom
  @ViewChild('name', { static: true }) focusElement: ElementRef;
  contentForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private feedback: FeedbackService,
    public customersService: CustomersService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.contentForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  keyup(e: KeyboardEvent): void {
    if (KeyUp.charactersAndBackspace(e)) this.dirty = true;
  }

  submit(form: NgForm): void {
    const customer = new Customer(form.value);
    this.customersService.create(customer).subscribe(
      () => {
        this.feedback.openSuccessToast('De nieuwe klant is toegevoegd');
        this.router.navigate(['admin', 'klanten']);
      },
      (err) => {
        this.feedback.openErrorToast(err);
      },
    );
  }

  cancel(): void {
    this.feedback.openNeutralToast();
    this.router.navigate(['admin', 'klanten']);
  }

  ngAfterViewInit(): void {
    this.focusElement.nativeElement.focus();
    this.changeDetector.detectChanges();
  }
}
